import axios from 'axios';
import config from "../lib/config.js";
import { Encryptdata, Decryptdata } from './encryprdata.js';

export const loginAdmin = async (payload) => {
    console.log("admin login ", payload, config.AdminAPI)
    // let data = {"data":payload}
    try {
        var resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/adminlogin`,
            "data": { data: Encryptdata(payload) },
            "headers": {
                "Content-Type": 'application/json'
            },

        })
        console.log("returned response", resp.data)
        let response = Decryptdata(resp.data)
        console.log("resssss", response);
        if (response)

            localStorage.setItem("token", response.token);

        return response;
    }
    catch (err) {
        console.log("err", err)
    }


}

export const changePassword = async (payload) => {
    try {
        
        var resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/changePassword`,
            "data": { data: Encryptdata(payload) },
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        })
        console.log("returned response", resp.data)
        let response = Decryptdata(resp.data)
        console.log("resssss", response);

        return response;

    } catch (error) {
        console.log("Change Password Error", error)
    }
}

export const getarticle = async () => {
    try {
        var resp = await axios({
            'method': 'GET',
            'url': `${config.AdminAPI}/getarticle`,

        })
        console.log("member resp", resp.data)
        let response = Decryptdata(resp.data)

        return response;
    }
    catch (err) { console.log("err in article list", err) }
}

export const getAdminDetails = async () => {
    try {
        var resp = await axios({
            'method': 'GET',
            'url': `${config.AdminAPI}/getAdminDetails`,
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        }) 
        console.log("Admin Details ", resp);

        let response = Decryptdata(resp.data);

        return response;
        
    } catch (error) {
        console.log("Get Admin Error", error)
    }
}



export const sendOTPtoEmail = async () => {
    try {
        var resp = await axios({
            'method': 'GET',
            'url': `${config.AdminAPI}/getEmailChangeOTP`,
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        }) 
        console.log("Admin Details ", resp);

        let response = Decryptdata(resp.data);

        return response;
        
    } catch (error) {
        console.log("Get Admin Error", error)
    }
}

export const checkOldEmail = async (payload) => {
    try {
        
        var resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/checkEmailOTP`,
            "data": { data: Encryptdata(payload) },
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        })
        console.log("returned response", resp.data)
        let response = Decryptdata(resp.data)
        console.log("resssss", response);

        return response;

    } catch (error) {
        console.log("Change Password Error", error)
    }
}

export const checkNewEmail = async (payload) => {
    try {
        
        var resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/checkNewEmailOTP`,
            "data": { data: Encryptdata(payload) },
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        })
        console.log("returned response", resp.data)
        let response = Decryptdata(resp.data)
        console.log("resssss", response);

        return response;

    } catch (error) {
        console.log("Change Password Error", error)
    }
}

export const sendOTPtoNewEmail = async (payload) => {
    try {
        
        var resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/newEmailChangeOTP`,
            "data": { data: Encryptdata(payload) },
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        })
        console.log("returned response", resp.data)
        let response = Decryptdata(resp.data)
        console.log("resssss", response);

        return response;

    } catch (error) {
        console.log("Change Password Error", error)
    }
}

export const getaboutuser = async () => {
    try {
        var resp = await axios({
            'method': 'GET',
            'url': `${config.AdminAPI}/getaboutuser`,
        })
        console.log("member resp", resp.data)
        let response = Decryptdata(resp.data)

        return response;

    }
    catch (err) { console.log("err in article list", err) }
}

export const customtokenfetch = async (data) => {
    try {
        const resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/tokenfetchapi`,
            "headers": {
                "Content-Type": 'application/json'
            },

        })
        console.log("returned response", resp.data)
        if (resp.data.token)
            localStorage.setItem("token", resp.data.token);

        return resp.data;
    }
    catch (err) {
        console.log("err", err)
    }
}

export const check = async () => {

    try {
        var data = { "test": "testdata" }
        var resp = await axios({
            "method": "POST",
            'url': `${config.AdminAPI}/checkroute`,
            "data": data,
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        })

    }
    catch (err) { console.log("err",err) }

}