import Config from "../lib/config";
import Crypto from "crypto-js";

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const NumANdDotOnly = (data) => {
  var data = data.toString();
  var str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        : data
      : data
    : data;
  return str.toString().replace(Config.NumDigitOnly, "");
};
export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly, "");
};

export const EncryptString = (value , secret) => {
  console.log("Before encruypted" , value);
  const encryptedString = Crypto.AES.encrypt(value , secret)?.toString(); //atob( localStorage.getItem("USRDT"))
  console.log("Encrypted string" , encryptedString);
  return encryptedString;
}

export const DecryptString = (value , secret) => {
  const decryptedString = Crypto.AES.decrypt(value , secret);
  let decryptedData = JSON.parse(decryptedString.toString(Crypto.enc.Utf8));
  return decryptedData;
}