import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { MdSpaceDashboard } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { IoTicket } from "react-icons/io5";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { HiAnnotation } from "react-icons/hi";
import { BiBookContent } from "react-icons/bi";
import { FaQuestionCircle } from "react-icons/fa";
import { IoGameController } from "react-icons/io5";
import { IoShareSocialSharp } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { CiDeliveryTruck } from "react-icons/ci";
import { MdOutlineContactSupport } from "react-icons/md";

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html"><img src={require('../../assets/images/logos.png')} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini" href="index.html"><img src={require('../../assets/images/playicon.png')} alt="logo" /></a>
        </div>
        <ul className="nav">

          {/* <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Navigation</Trans></span>
          </li> */}
          <li className={this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><MdSpaceDashboard /></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/usermanagementlist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/usermanagementlist">
              <span className="menu-icon"><GrUserManager /></span>
              <span className="menu-title"><Trans>User Management</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/supportticketlist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/supportticketlist">
              <span className="menu-icon"><IoTicket /></span>
              <span className="menu-title"><Trans>Support Ticket</Trans></span>
            </Link>
          </li>
          {/* <li className={ this.isPathActive('/chathistorylist') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/chathistorylist">
              <span className="menu-icon"><IoChatbubbleEllipsesSharp/></span>
              <span className="menu-title"><Trans>Chat History</Trans></span>
            </Link>
          </li> */}
          <li className={this.isPathActive('/salerequestlist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/salerequestlist">
              <span className="menu-icon"><HiAnnotation /></span>
              <span className="menu-title"><Trans>Sales Request</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/cmsandfaq') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/cmsandfaq">
              <span className="menu-icon"><BiBookContent /></span>
              <span className="menu-title"><Trans>Content Management</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/faqlist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/faqlist">
              <span className="menu-icon"><FaQuestionCircle /></span>
              <span className="menu-title"><Trans>FAQ List</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/gamelist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/gamelist">
              <span className="menu-icon"><IoGameController /></span>
              <span className="menu-title"><Trans>Game List</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/joinUs') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/joinUs">
              <span className="menu-icon"><IoShareSocialSharp /></span>
              <span className="menu-title"><Trans>Join Us</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/contactUs') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/contactUs">
              <span className="menu-icon"><MdOutlineContactSupport /></span>
              <span className="menu-title"><Trans>Contact Us</Trans></span>
            </Link>
          </li>
          {/* <li className={this.isPathActive('/delivery') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/delivery">
              <span className="menu-icon"><CiDeliveryTruck /></span>
              <span className="menu-title"><Trans>Delivery Methods</Trans></span>
            </Link>
          </li> */}
          {/* <li className={ this.isPathActive('/profilelist') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/profilelist">
              <span className="menu-icon"><CgProfile/></span>
              <span className="menu-title"><Trans>Profile List</Trans></span>
            </Link>
          </li> */}

          <li className={this.isPathActive('/emailtemplatelist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/emailtemplatelist">
              <span className="menu-icon"><CgProfile /></span>
              <span className="menu-title"><Trans>Email Template</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/userdetail') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/userdetail">
              <span className="menu-icon"><CgProfile /></span>
              <span className="menu-title"><Trans>Profile</Trans></span>
            </Link>
          </li>

          <li className={this.isPathActive('/feelist') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/feelist">
              <span className="menu-icon"><CgProfile /></span>
              <span className="menu-title"><Trans>Fee Settings</Trans></span>
            </Link>
          </li>

          {/* <li className={ this.isPathActive('/tables') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={true}>
              <div>
                <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/usermanagementlist') ? 'nav-link active' : 'nav-link' } to="/usermanagementlist"><Trans>User Management</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/supportticketlist') ? 'nav-link active' : 'nav-link' } to="/supportticketlist"><Trans>Support Ticket Management</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/chathistorylist') ? 'nav-link active' : 'nav-link' } to="/chathistorylist"><Trans>Chat History</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/cmsandfaq') ? 'nav-link active' : 'nav-link' } to="/cmsandfaq"><Trans>Content Management</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/faqlist') ? 'nav-link active' : 'nav-link' } to="/faqlist"><Trans>FAQ List</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/userlist') ? 'nav-link active' : 'nav-link' } to="/userlist"><Trans>Profile List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/promotionlist') ? 'nav-link active' : 'nav-link' } to="/promotionlist"><Trans>Promotion List</Trans></Link></li>
                   <li className="nav-item"> <Link className={ this.isPathActive('/categorylist') ? 'nav-link active' : 'nav-link' } to="/categorylist"><Trans>Category List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/currencylist') ? 'nav-link active' : 'nav-link' } to="/currencylist"><Trans>Currency List</Trans></Link></li>
                   <li className="nav-item"> <Link className={ this.isPathActive('/cmsandfaq') ? 'nav-link active' : 'nav-link' } to="/cmsandfaq"><Trans>Cms And List Faq</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/subscriberslist') ? 'nav-link active' : 'nav-link' } to="/subscriberslist"><Trans>Subscribers List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/sociallist') ? 'nav-link active' : 'nav-link' } to="/sociallist"><Trans>Social List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/servicefee') ? 'nav-link active' : 'nav-link' } to="/servicefee"><Trans>Service Fee Management</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/userlist') ? 'nav-link active' : 'nav-link' } to="/userlist"><Trans>User List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/faqlist') ? 'nav-link active' : 'nav-link' } to="/faqlist"><Trans>FAQ List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/reportlist') ? 'nav-link active' : 'nav-link' } to="/reportlist"><Trans>Report Token List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/nfttaglist') ? 'nav-link active' : 'nav-link' } to="/nfttaglist"><Trans>Nft Tag List</Trans></Link></li>             
                  <li className="nav-item"> <Link className={ this.isPathActive('/tokenlist') ? 'nav-link active' : 'nav-link' } to="/tokenlist"><Trans>Token List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/emailtemplatelist') ? 'nav-link active' : 'nav-link' } to="/emailtemplatelist"><Trans>Email Template List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/article') ? 'nav-link active' : 'nav-link' } to="/article"><Trans>Article List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/aboutuser') ? 'nav-link active' : 'nav-link' } to="/aboutuser"><Trans>About User List</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/referral') ? 'nav-link active' : 'nav-link' } to="/referral"><Trans>Referral</Trans></Link></li>

                  <li className="nav-item"> <Link className={ this.isPathActive('/npo-list') ? 'nav-link active' : 'nav-link' } to="/npo-list"><Trans>Non profit organisation</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/promo-drops') ? 'nav-link active' : 'nav-link' } to="/promo-drops"><Trans> Free Drops </Trans></Link></li>
                  
                  
                </ul>
              </div>
            </Collapse>
          </li> */}




        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);


