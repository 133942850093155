let key = {};
let IPFS_IMG = " https://naifty.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let Back_Url_Game = "";
let Back_Url_Social = "";
let Back_Url_User = "";
let Back_Url_Contact_Support = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var EnvName = "demo";
export const ENCODEKEY = "encodenft@x"
console.log("config_ENCODEKEY", ENCODEKEY, process.env);
var Front_market_Url = ''
var URL = "";

var CONTRACT = ""

if (EnvName === "local") {
  URL = "http://localhost:2056"
  Back_Url = "http://localhost:2056/admin";

  Back_Url_Game = "http://localhost:2056/game";
  Back_Url_Contact_Support = "http://localhost:2056/contactus-support";
  Back_Url_Social = "http://localhost:2056/social"
  Back_Url_User = "http://localhost:2056/user"

  CONTRACT = "0x98eF334f43DB9170D3A9bC73Ab1fEA2fAe25668D"

  Front_market_Url = "http://localhost:3000/naiftyadmin";
  var image_url = "hhttp://localhost:3331/token";
  ImG = "http://localhost:3331";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var chainlist = [97, 43113]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://bsc-testnet-rpc.publicnode.com";
  // var Back_Url_Token = "https://naifty.io/v1";
  var Back_Url_Token = "http://localhost:3331/v1";
}
if (EnvName === "demo") {
  URL = "https://backend-playforce.maticz.in";
  Back_Url = "https://backend-playforce.maticz.in/admin";

  Back_Url_Game = "https://backend-playforce.maticz.in/game"; //add url
  Back_Url_Contact_Support = "https://backend-playforce.maticz.in/contactus-support" //add url
  Back_Url_Social = "https://backend-playforce.maticz.in/social" //add url
  Back_Url_User = "https://backend-playforce.maticz.in/user" //add url

  CONTRACT = "0x98eF334f43DB9170D3A9bC73Ab1fEA2fAe25668D"

  Front_market_Url = "http://nftdemo.bimaticz.com/naifty";
  var image_url = "http://192.53.121.26:2525/token";
  ImG = "http://192.53.121.26:7017";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var chainlist = [97, 43113]
  var versions = ["0x61", "0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "https://backend-playforce.maticz.in";
}

if (EnvName === "production") {
  FRONT_URL = "https://contorls-ytrb.naifty.io";
  Front_market_Url = 'https://naifty.io'
  
  URL = 'https://api.naifty.io/v1';
  Back_Url = 'https://api.naifty.io/v1/admin'

  Back_Url_Game = "http://192.53.121.26:7017/v1/game"; //add url
  Back_Url_Contact_Support = "https://api.naifty.io/v1/contactus-support" //add url
  Back_Url_Social = "https://api.naifty.io/v1/social" //add url
  Back_Url_User = "https://api.naifty.io/v1/user" //add url

  CONTRACT = "0x98eF334f43DB9170D3A9bC73Ab1fEA2fAe25668D"

  var image_url = 'https://api.naifty.io'
  ImG = 'https://api.naifty.io';
  var tradeAddress = "0xC9Cfa2dd1Ed7Bc29C7d9B990b2DE7Ee630478ec4";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "1";
  var ETHCHAIN = 1
  var BNBCHAIN = 56
  var chainlist = [56, 1]
  var chainId = "0x1";
  var BNBversion = "0x38"
  var ETHversion = "0x1"
  var versions = ["0x38", "0x1"]
  var ETHprovider = "https://mainnet.infura.io/v3/8bd0c393365f4c559c7ca6179a12fea6";
  var BNBprovider = "https://bsc-dataseed.binance.org/";
  var Back_Url_Token = "https://naifty.io/v1";
}

key = {
  URL: `${URL}`,
  AdminAPI: `${Back_Url}`,
  GameAPI: `${Back_Url_Game}`,
  UserAPI: `${Back_Url_User}`,
  Contract: CONTRACT,
  Contact_Support: `${Back_Url_Contact_Support}`,
  SocialAPI: `${Back_Url_Social}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBprovider,
  ETHprovider: ETHprovider,
  ETHCHAIN: ETHCHAIN,
  BNBCHAIN: BNBCHAIN,
  chainlist: chainlist,
  bnbver: BNBversion,
  ethver: ETHversion,
  versionlist: versions,
  ENVname: EnvName,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  ETHTRADE: ETHTRADE,
  BNBTRADE: BNBTRADE,
  AdminAddress: "0x8f4ad1aE5AB57b2DA6DCd0Be762048bB4692C0Ce",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url: Front_market_Url,
  networkVersion: networkVersion
};

export default key;
